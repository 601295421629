





























































import WidgetMixins from '../../../../mixins/WidgetMixins';
import Component, { mixins } from 'vue-class-component';
import { loadWidget } from '@/utils/helpers';
import { Emit, Prop } from 'vue-property-decorator';
import { PayloadState } from '@/types/types';

@Component({
  name: 'CopyAPISecretDialog',
  components: {
    SkeletonPreloader: loadWidget('widgets/SkeletonPreloader'),
  },
})
export default class CopyAPISecret extends mixins(WidgetMixins) {
  @Prop({ default: false, required: true }) state!: boolean;
  @Prop({ default: false }) dialogLoading!: boolean;
  @Prop({ default: false }) loading!: boolean; // button loading
  @Prop({ default: null }) apiSecret!: string;

  @Emit('copySecret')
  copySecret(): string {
    return this.apiSecret;
  }

  @Emit('actions')
  close(): PayloadState {
    return {
      idx: 'details',
      state: false,
    };
  }
}
